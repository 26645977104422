// styling
import styles from "./styles.module.scss";

// components
import { NavLink } from "react-router-dom";
import SearchPopup from "./SearchPopup";
import SettingsPopup from "./SettingsPopup";

// hooks
import { useState } from "react";
import useStoreRoute from "@hooks/useStoreRoute";
import { useShopProvider } from "@contexts/shopContext";
import { useAuth } from "@contexts/authContext";

const BottomNav = () => {
  const { isLoggedIn , logout } = useAuth();

  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);
  const isStoreRoute = useStoreRoute();
  const { setCartOpen } = useShopProvider();

  return (
    <div className={styles.container}>
      
      {isLoggedIn ?  <NavLink className={styles.button} to="/settings" aria-label="Account">
        <i className="icon-user" />
      </NavLink> : <NavLink className={styles.button} to="/login" aria-label="Account">
        <i className="icon-user" />
      </NavLink>}
      {isLoggedIn &&  <NavLink className={styles.button} to="/license" aria-label="Account">
        <i className="icon-ticket-regular" />
      </NavLink>}
      <NavLink className={styles.button} to="/" aria-label="Home">
        <i className="icon-house" />
      </NavLink>
      {isStoreRoute && (
        <button
          className={styles.button}
          aria-label="Shopping cart"
          onClick={() => setCartOpen(true)}
        >
          <i className="icon-cart" />
        </button>
      )}

      <button
        className={styles.button}
        aria-label="Settings"
        onClick={() => setSettingsPopupOpen(true)}
      >
        <i className="icon-gear-regular" />
      </button>
      
      {isLoggedIn &&  <button
        className={styles.button}
        aria-label="Cerrar"
        onClick={() => logout()}
      >
        <i className="icon-exit" />
      </button>}
      <SearchPopup open={searchPopupOpen} onClose={setSearchPopupOpen} />
      <SettingsPopup
        open={settingsPopupOpen}
        onClose={() => setSettingsPopupOpen(false)}
      />
    </div>
  );
};

export default BottomNav;
