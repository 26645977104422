
// GA
import ReactGA from "react-ga4";

// utils
import { lazy, Suspense } from "react";

// styles
import ThemeStyles from "@styles/theme";
import "./style.scss";

// libs styles
import "react-toastify/dist/ReactToastify.min.css";
import "react-grid-layout/css/styles.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// fonts
import "@fonts/icomoon/icomoon.woff";

// contexts
import { SidebarProvider } from "@contexts/sidebarContext";
import { ThemeProvider } from "styled-components";
import { AuthProvider,useAuth } from "@contexts/authContext";

// hooks
import { useThemeProvider } from "@contexts/themeContext";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import useAuthRoute from "@hooks/useAuthRoute";

// utils
import { StyleSheetManager } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { preventDefault } from "@utils/helpers";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "@components/LoadingScreen";
import Sidebar from "@layout/Sidebar";
import BottomNav from "@layout/BottomNav";
import Navbar from "@layout/Navbar";
import ShoppingCart from "@widgets/ShoppingCart";
import ScrollToTop from "@components/ScrollToTop";

// pages
const Game= lazy(() => import("@pages/Game"));
const Statistics = lazy(() => import("@pages/Statistics"));
const Statistics2024 = lazy(() => import("@pages/Statistics2024"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));
const Schedule = lazy(() => import("@pages/Schedule"));
const Login = lazy(() => import("@pages/Login"));
const DIAN = lazy(()=> import("@pages/Dian"));
const SignUp = lazy(() => import("@pages/SignUp"));
const Settings = lazy(() => import("@pages/Settings"));
const Licencse = lazy(() => import("@pages/License"));
const ReportGame = lazy(() => import("@pages/ReportGame"));
const PlayerSumary= lazy(() => import("@pages/PlayerSumary"));
const AllUsers= lazy(() => import("@pages/AllUsers"));
const AllWarnings= lazy(() => import("@pages/AllWarnings"));
const AllGames = lazy(() => import("@pages/AllGames"));
const GamesManagement= lazy(() => import("@pages/GameManagement"));
const TeamSummary = lazy(() => import("@pages/TeamSummary"));
const GameDay  = lazy(() => import("@pages/GameDay"));
const PlayerProfile  = lazy(() => import("@pages/PlayerProfileResume"));

//default page 
const DefaultPage = lazy(() => import("@pages/DefaultPage"));
const Teampage= lazy(() => import("@pages/Teampage"));


const PrivateRoute = ({ element, roles }) => {
    const { isLoggedIn,hasRole } = useAuth();

  const hasRequiredRole = hasRole(roles);
  return isLoggedIn && hasRequiredRole ? element : <Navigate to="/" />;
};

ReactGA.initialize('G-JDKGC9DGSG');

const App = () => {
  const appRef = useRef(null);
  const { theme, direction } = useThemeProvider();
  const { width } = useWindowSize();
  const isAuthRoute = useAuthRoute();
  const location = useLocation();


  // Google Analytics init
  const gaKey = process.env.REACT_APP_PUBLIC_GA;
  gaKey && ReactGA.initialize(gaKey);

  // auto RTL support for Material-UI components and styled-components

  const plugins = direction === "rtl" ? [rtlPlugin] : [];

  const muiTheme = createTheme({
    direction: direction,
  });

  const cacheRtl = createCache({
    key: "css-rtl",
    stylisPlugins: plugins,
  });

  useEffect(() => {
    // scroll to top on route change
    appRef.current && appRef.current.scrollTo(0, 0);

    preventDefault();
  }, []);



  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return (
    <CacheProvider value={cacheRtl}>
      <MuiThemeProvider theme={muiTheme}>
        <AuthProvider>
          <SidebarProvider>
            <ThemeProvider theme={{ theme: theme }}>
              <ThemeStyles />
              <ToastContainer
                theme={theme}
                autoClose={2500}
                position={direction === "ltr" ? "top-right" : "top-left"}
              />
              <StyleSheetManager stylisPlugins={plugins}>
                <div
                  className={`app ${isAuthRoute ? "fluid" : ""}`}
                  ref={appRef}
                >
                  <ScrollToTop />
                  {!isAuthRoute && (
                    <>
                      <Sidebar />
                      {width < 768 && <Navbar />}
                      {width < 768 && <BottomNav />}
                    </>
                  )}
                  <div className="app_container">
                    <div className="app_container-content d-flex flex-column flex-1">
                      <Suspense fallback={<LoadingScreen />}>
                        <Routes>
                          {/* public routes*/}
                          <Route path="*" element={<PageNotFound />} />
                          <Route path="/" element={<DefaultPage />} />
                          <Route path="/teams" element={<Teampage />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/dian" element={<DIAN />} />
                          <Route path="/schedule" element={<Schedule />} />
                          <Route path="/game/:gamecode" element={<Game />} />
                          <Route path="/team/:teamcode" element={<TeamSummary />} />
                          <Route path="/statistics" element={<Statistics />} />
                          <Route path="/statistics2024" element={<Statistics2024 />} />
                          {/* coach*/}
                          <Route path="/players-sumary" element={<PrivateRoute element={<PlayerSumary />} roles={[1]} />} />
                          {/* management*/}
                          <Route path="/games-management" element={<PrivateRoute element={<GamesManagement />} roles={[5]} />} />
                          <Route path="/all-users" element={<PrivateRoute element={<AllUsers />} roles={[5,3]} />} />     
                          <Route path="/all-warning" element={<PrivateRoute element={<AllWarnings />} roles={[5,3]} />} />    
                          <Route path="/team-gear" element={<PrivateRoute element={<AllGames />} roles={[5,3]} />} />    
                          <Route path="/gameDay/:gamecode" element={<PrivateRoute element={<GameDay />} roles={[5,3]} />} />    

                          <Route path="/settings" element={<PrivateRoute element={<Settings />} roles={[1, 2, 3, 4, 5]} />} />
                          <Route path="/license" element={<PrivateRoute element={<Licencse />} roles={[1, 2, 3, 4, 5]} />} />
                          <Route path="/reportGame/:gamecode" element={<PrivateRoute element={<ReportGame />} roles={[2,5]} />} />

                          {/* Jugador*/}
                          <Route path="/player-profile" element={<PrivateRoute element={<PlayerProfile />} roles={[1,2]} />} />

                        </Routes>
                      </Suspense>
                    </div>
                  </div>
                  <ShoppingCart isPopup />
                </div>
              </StyleSheetManager>
            </ThemeProvider>
          </SidebarProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export default App;
