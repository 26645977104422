// AuthContext.js

import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  LINKS,
  player,
  coach,
  asistant,
  admin,
  referee,
} from "@constants/links";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useState([]);
  const [links, setLinks] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("authToken") || null);

  const login = (roles, user, token) => {
    let access = [];
    setIsLoggedIn(true);
    setUserRoles(roles);
    setUserData(user);
    setToken(token);

    if (hasRoles(1, roles)) {
      access =[...access,...coach];
    }

    if (hasRoles(2, roles)) {
      access =[...access,...player];
    }

    if (hasRoles(3, roles)) {

      access =[...access,...player,...asistant];
    }

    if (hasRoles(4, roles)) {
      access =[...access,...player,...referee];
    }
    if (hasRoles(5, roles)) {
      access =[...access,...player,...asistant,...admin];
    }

    setLinks([
      {
        title: "Opciones",
        icon: "table",
        pages: [...access],
      },
    ]);
    localStorage.setItem("authToken", token);
    localStorage.setItem("tokenExpiration", new Date().getTime() + 3600000);
    localStorage.setItem(
      "authData",
      JSON.stringify({ roles: roles, user: user })
    );
  };

  const logout = () => {
    // Lógica de cierre de sesión
    setIsLoggedIn(false);
    setUserRoles([]);
    setToken(null);
    setLinks([]);
    localStorage.removeItem("authToken");
    localStorage.removeItem("tokenExpiration");
  };

  const hasRole = (role) => {
    if (userRoles.length > 0) {
      let a = userRoles.split(",");

      for (let index = 0; index < role.length; index++) {
        const element = role[index];
        for (let index = 0; index < a.length; index++) {
          const element2 = a[index];
          if(parseInt(element2) === parseInt(element)){
            return true;
          }
        }
      }
    }
    return false;
  };
  const hasRoles = (role, roles) => {
    let flag = false;
    if (roles.length > 0) {
      let a = roles.split(",");

      for (let index = 0; index < a.length; index++) {
        const element2 = a[index];
        if (parseInt(element2) === parseInt(role)) {
          flag = true;
        }
      }
    }
    return flag;
  };

  useEffect(() => {
    let access = [];
    const storedToken = localStorage.getItem("authToken");
    const storedExpiration = localStorage.getItem("tokenExpiration");

    if (storedToken && storedExpiration) {
      const data = JSON.parse(localStorage.getItem("authData"));

      setToken(storedToken);
      setUserRoles(data.roles);
      setUserData(data.user);
      setIsLoggedIn(true);
      navigate(1);

  
      if (hasRoles(1,  data.roles)) {
        access =[...access,...coach];
      }
  
      if (hasRoles(2,  data.roles)) {
        access =[...access,...player];
      }
  
      if (hasRoles(3,  data.roles)) {
  
        access =[...access,...player,...asistant];
      }
  
      if (hasRoles(4,  data.roles)) {
        access =[...access,...player,...referee];
      }
      if (hasRoles(5,  data.roles)) {
        access =[...access,...player,...asistant,...admin];
      }

      setLinks([
        {
          title: "Opciones",
          icon: "table",
          pages: [...access],
        },
      ]);
      const expirationTime = new Date(parseFloat(storedExpiration)).getTime();
      const currentTime = new Date().getTime();

      // Verificar si el token ha expirado y renovar si es necesario
      if (expirationTime < currentTime) {
        // Token ha expirado, realizar la lógica para renovar el token aquí
        // ...

        // Después de renovar el token, actualiza la información en el estado y el almacenamiento local.
        // Simulamos una renovación inmediata, pero en la práctica, debes realizar la renovación asincrónica.
        const newToken = "nuevoToken"; // Reemplaza con el token renovado
        const newExpiration = new Date(new Date().getTime() + 3600000); // 1 min de duración
        setToken(storedToken);
       // localStorage.setItem("authToken", newToken);
      //  localStorage.setItem("tokenExpiration", newExpiration);
      } else if (expirationTime - currentTime < 60000) {
        // Menos de 1 minuto antes de la expiración
        // Cerrar sesión si el token está a punto de expirar y no se puede renovar
        logout();
      }
    }
  }, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez al montar el componente.


  const uploadUserData = (user) =>{
    setUserData(user);

    let json = JSON.parse(localStorage.getItem("authData"));
    localStorage.setItem(
      "authData",
      JSON.stringify({...json,user:user})
    );
 
  }
  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, hasRole, userData,uploadUserData, token, links }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
