// styling
import styles from './styles.module.scss';

// components
import {NavLink} from 'react-router-dom';

// utils
import PropTypes from 'prop-types';
import logoPNG from '@assets/logo.png';

const Logo = ({size = 'md'}) => {
    return (
        <NavLink className={`${styles.logo} ${styles[size]}`} to="/">
         {/*  Copa
            <span className={styles.logo_highlight}>
                <span>soccer</span>bvghhhhhh 
            </span>
          */} 
            <img className="c-pointer" src={logoPNG} alt="user" />
        </NavLink>
    )
}

Logo.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'xl'])
}

export default Logo